import React from 'react';


function Home() {
  return (
    <section className="home-main-content">
      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell small-12 medium-11 large-10">
            <div className="home-main-content__intro-text">
              <h1>I'm Corey Rotstein</h1>
              
              <p>Your friendly neighborhood Front-End and Full Stack Web Developer in training. By day (and often night), I transform zeros and ones into immersive, interactive experiences, crafting the Internet with detailed projects and clean, efficient code.</p>

              <p>When I'm not coding, you'll find me indulging in my passion for cooking, whipping up delicious dishes that hit the spot. My musical tastes are as diverse as my coding skills - from the smooth grooves of jazz to the heartfelt strums of country and the energetic beats of rock, I find my rhythm in every genre.</p>

              <p>I tread the path of minimalism, finding beauty in simplicity and order. And when it's time to unplug, you can catch me hiking the trails, camping with the bears and the wolves or just getting lost in the forest (cause what else do developers do on vacation?) or just embracing the great outdoors and recharging my creative batteries.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
