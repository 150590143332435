import React, { useEffect } from 'react';
import $ from 'jquery'; // Import jQuery
import 'foundation-sites'; // Import Foundation JS

import Skills from './Skills';
import Playlist from './Playlist';
import WhyChooseMe from './WhyChooseMe';

const About = () => {
  useEffect(() => {
    // Initialize Foundation after component mounts
    $(document).foundation();
  }, []);

  return (
    <div className="grid-container">
      <div className="grid-x align-center">
        <div className="cell small-12 medium-11 large-10">
          <h1>About Me</h1>
          <div className="tabs-container">
            <ul className="tabs" data-tabs id="tabs">
              <li className="tabs-title is-active"><a href="#bio" aria-selected="true">Bio</a></li>
              <li className="tabs-title"><a href="#why-hire-me">Why Hire Me</a></li>
              <li className="tabs-title"><a href="#skills">My Skills</a></li>
              <li className="tabs-title"><a href="#playlist">My Playlists</a></li>
            </ul>

            <div className="tabs-content" data-tabs-content="tabs">
              <div className="tabs-panel is-active" id="bio">
                <div className="about-page__intro-text" data-aos="fade-up">
                  <p>I am a web developer with a strong background in creating responsive websites and optimizing user experiences using a range of technologies. My expertise includes React, Foundation 6, Bootstrap, and various other frameworks and languages.</p>

                  <p>I am proficient in CMS platforms such as WordPress, Shopify, and Squarespace, and excel in applying UI/UX design principles and optimization strategies. My front-end development skills encompass HTML, CSS, JavaScript, and React.</p>

                  <p>Additionally, I am skilled in utilizing Adobe Creative Suite, including Photoshop and Illustrator, to enhance branding and user engagement. I am known for delivering projects on time and exceeding client expectations through collaborative teamwork and innovative problem-solving. I am eager to leverage my extensive experience in both web development and graphic design to drive impactful results in a dynamic professional setting.</p>
                </div>
              </div>

              <div className="tabs-panel" id="why-hire-me">
                <WhyChooseMe />
              </div>

              <div className="tabs-panel" id="skills">
                <Skills />
              </div>

              <div className="tabs-panel" id="playlist">
                <Playlist />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
