import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../data/projectsData'; // Adjust path if needed
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import ProjectCard from './ProjectCard';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState('All');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Category mapping
  const categoryMapping = {
    'all': 'All',
    'web-development': 'Web Development',
    'design': 'Design',
    'agency': 'Agency',
    'freelance': 'Freelance'
  };

  // Reverse mapping from display category to URL-friendly format
  const reverseCategoryMapping = Object.fromEntries(
    Object.entries(categoryMapping).map(([key, value]) => [value, key])
  );

  useEffect(() => {
    // Set filter from URL on initial load
    const urlCategory = searchParams.get('filter') || 'all';
    const displayCategory = categoryMapping[urlCategory] || 'All';
    setFilter(displayCategory);

    const fetchProjects = async () => {
      try {
        const projectsQuery = query(
          collection(db, 'Projects'),
          orderBy('index', 'asc') // Order by the field of your choice
        );

        const querySnapshot = await getDocs(projectsQuery);
        
        // Log the querySnapshot to check if data is being fetched
        console.log("Query Snapshot:", querySnapshot);

        const projectsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Log the fetched project data
        console.log("Fetched Projects:", projectsData);

        setProjects(projectsData);
      } catch (error) {
        console.error("Error fetching projects: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [searchParams]);

  const handleFilterChange = (displayCategory) => {
    setFilter(displayCategory);
    const urlCategory = reverseCategoryMapping[displayCategory] || 'all';
    setSearchParams({ filter: urlCategory });
  };

  // Filter projects based on selected category
  const filteredProjects = filter === 'All'
    ? projects
    : projects.filter(project => {
        const categories = Array.isArray(project.category)
          ? project.category
          : [project.category];
        return categories.map(cat => cat.toLowerCase()).includes(filter.toLowerCase());
      });

  // Log filtered projects for debugging
  console.log("Filtered Projects:", filteredProjects);

  return (
    <div className="grid-container" data-aos="fade-up">
      <div className="grid-x align-center">
        <div className="cell small-12 medium-11 large-10">
          <h1>Projects</h1>
          <div className="grid-x grid-margin-x">
            <div className="cell auto">
              <div className="tabs-container">
                <div className="tabs">
                  {Object.values(categoryMapping).map((displayCategory) => (
                    <div className={filter === displayCategory ? 'tabs-title is-active' : 'tabs-title'}>
                      <button
                        key={displayCategory}
                        onClick={() => handleFilterChange(displayCategory)}
                      >
                        {displayCategory}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <p>Loading projects...</p>
          ) : (
            <div className="masonry-grid">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project, index) => (
                  <ProjectCard
                    key={project.id}
                    title={project.title}
                    imageUrl={project.imageUrl}
                    category={project.category}
                    index={index}
                    linkUrl={project.linkUrl}
                  />
                ))
              ) : (
                <p>No projects found.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
