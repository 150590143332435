// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyA0--4abEgedQTtVY8oDa55ClCrLAm0VMw",
  authDomain: "my-portfolio-1fc61.firebaseapp.com",
  projectId: "my-portfolio-1fc61",
  storageBucket: "my-portfolio-1fc61.appspot.com",
  messagingSenderId: "478194678330",
  appId: "1:478194678330:web:830514eedd8bdb2f4fd303",
  measurementId: "G-F879BLSBVQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
