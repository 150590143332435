import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Correct import statement
import $ from 'jquery';
import 'foundation-sites';
import logo from '../images/new logo(1).png';

function Header() {
  useEffect(() => {
    $(document).foundation();

    // Handle closing the off-canvas menu on link click
    $('.nav-link').on('click', function () {
      $('#offCanvas').foundation('close');
    });

    return () => {
      // Clean up event listeners on component unmount
      $('.nav-link').off('click');
    };
  }, []);

  return (
    <header className="site-header">
      <div className="grid-container">
        <div className="grid-x grid-margin-x" style={{ justifyContent: 'space-between' }}>
          {/* Logo */}
          <div className="cell small-4 medium-3 large-2">
            <NavLink to="/" className={({ isActive }) => (isActive ? 'site-header__logo active' : 'site-header__logo')}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>

          {/* Toggle and Main Menu */}
          <div className="cell small-3 large-auto text-right align-self-middle">
            <div className="off-canvas__toggle-container" data-responsive-toggle="main-menu" data-hide-for="large">
              <button className="off-canvas__toggle" type="button" data-toggle="offCanvas">
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            
            <nav className="site-navigation-container">
              <ul className="site-navigation" id="main-menu">
                <li className={`nav-item ${window.location.pathname === '/' ? 'active' : ''}`}>
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                  >
                    Home
                  </NavLink>
                </li>
                <li className={`nav-item ${window.location.pathname === '/about' ? 'active' : ''}`}>
                  <NavLink
                    to="/about"
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                  >
                    About
                  </NavLink>
                </li>
                <li className={`nav-item ${window.location.pathname === '/projects' ? 'active' : ''}`}>
                  <NavLink
                    to="/projects"
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                  >
                    Projects
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          {/* Off-canvas menu */}
          <div className="off-canvas position-right" id="offCanvas" data-off-canvas data-transition="overlap">
            <ul className="site-navigation" id="main-menu">
              <li className={`nav-item ${window.location.pathname === '/' ? 'active' : ''}`}>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                >
                  Home
                </NavLink>
              </li>
              <li className={`nav-item ${window.location.pathname === '/about' ? 'active' : ''}`}>
                <NavLink
                  to="/about"
                  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                >
                  About
                </NavLink>
              </li>
              <li className={`nav-item ${window.location.pathname === '/projects' ? 'active' : ''}`}>
                <NavLink
                  to="/projects"
                  className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                >
                  Projects
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
