import React from 'react';

const Playlist = () => (
  <div class="my-playlist" data-aos="fade-up">
    <ul className="timeline">
        <li>
            <iframe src="https://open.spotify.com/embed/playlist/0KIL3gtJoZUT3xUsuJ3tTA?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <p>My FAVOURITE playlist. Made by me for any type of drives wether it's night time or day time or you are on a road trip this is the playlist I will always have playing</p>
        </li>
        <li>
            <iframe src="https://open.spotify.com/embed/playlist/3LFIBdP7eZXJKqf3guepZ1?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <p>The perfect study beats, twenty four seven. with over 300 SONGS</p>
        </li>
        <li>
            <iframe src="https://open.spotify.com/embed/playlist/2F2MMHSjUpvn9dDBY7boae?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <p>Great Playlist for cooking & relaxing</p>
        </li>
        <li>
            <iframe src="https://open.spotify.com/embed/show/2X40qLyoj1wQ2qE5FVpA7x/video?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <p>Distractible Podcast</p>

        </li>
    </ul>
    <h4>More to Come</h4>
  </div>
);

export default Playlist;
