import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import ProjectsPage from './pages/ProjectsPage';
import AOS from 'aos';
import 'foundation-sites/dist/css/foundation.min.css';
import 'foundation-sites/dist/js/foundation.min.js';
import './App.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

// import ParticlesBackground from './components/ParticlesBackground';



AOS.init();

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
        <div className="off-canvas-content" data-off-canvas-content>     
          {/* <ParticlesBackground />    */}
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
    </Router>
  );
}

export default App;
