import React from 'react';
import About from '../components/About';

function AboutPage() {
  return (
    <section className="about-page">
      <About />
    </section>
  );
}

export default AboutPage;
