import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faLightbulb, faHandshake, faComments } from '@fortawesome/free-solid-svg-icons';

const WhyHireMe = () => (
  <div className="why-hire-section" data-aos="fade-up">
    <div className="why-hire-wrapper">
      <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2">
        <div className="cell">
          <div className="service-box">
            <div className="box-icon">
              <FontAwesomeIcon icon={faLaptopCode} />
            </div>
            <div className="box-content">
              <p className="title">Expertise in Modern Technologies</p>
              <p>Extensive experience with React, Foundation 6, and other cutting-edge technologies.</p>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="service-box">
            <div className="box-icon">
              <FontAwesomeIcon icon={faLightbulb} />
            </div>
            <div className="box-content">
              <p className="title">Creative Problem-Solving</p>
              <p>Innovative solutions tailored to meet project requirements and overcome challenges.</p>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="service-box">
            <div className="box-icon">
              <FontAwesomeIcon icon={faHandshake} />
            </div>
            <div className="box-content">
              <p className="title">Strong Work Ethic</p>
              <p>Committed to delivering high-quality work on time and exceeding client expectations.</p>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="service-box">
            <div className="box-icon">
              <FontAwesomeIcon icon={faComments} />
            </div>
            <div className="box-content">
              <p className="title">Excellent Communication</p>
              <p>Clear and effective communication to ensure smooth project collaboration.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WhyHireMe;
