import React from 'react';
import skillsData from '../data/skillsData';

const SkillsSection = () => {
  const { designSkills, codingSkills } = skillsData;

  return (
    <div className="skills" data-aos="fade-up">
      <h4>Coding Skills</h4>
      <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 align-center">
        {codingSkills.map(skill => (
          <div className="cell" key={skill.id}>
            <div className="skills__content">
              <i className={`skills__content__icon ${skill.icon}`}></i>
              <div>
                <p className="skills__content__title">{skill.skill}</p>
                <p className="skills__content__level">{skill.level}%</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <hr />
      <h4>Design Skills</h4>
      <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 align-center">
        {designSkills.map(skill => (
          <div className="cell" key={skill.id}>
            <div className="skills__content">
              <i className={`skills__content__icon ${skill.icon}`}></i>
              <div>
                <p className="skills__content__title">{skill.skill}</p>
                <p className="skills__content__level">{skill.level}%</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;
