import React from 'react';
import PropTypes from 'prop-types';

const ProjectCard = ({ title, description, imageUrl, index, category, linkUrl }) => {
  // Calculate delay based on index, default to 0 if index is invalid
  const delay = Number.isInteger(index) ? (index + 1) * 200 : 200;

  // Randomize card height to either 310px, 400px, or 454px
  const heights = ['310px', '400px', '454px'];
  const randomHeight = heights[Math.floor(Math.random() * heights.length)];

  // Ensure category is an array
  const categories = Array.isArray(category) ? category : [category];

  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
      <div
        className="masonry-grid-item__cardHover"
        data-aos="fade-up"
        data-aos-delay={`${delay}`}
        data-aos-duration="1300"
        style={{ height: randomHeight }}
      >
        <img src={imageUrl} alt={title} />
        <div className="masonry-grid-item__cardHover__content">
          <h3>{title}</h3>
          {/* Optional Description */}
          {description && <p>{description}</p>}
          <div className="categories">
            {categories.map((cat, index) => (
              <span key={index} className="category">
                {cat}
              </span>
            ))}
          </div>
        </div>
      </div>
    </a>
  );
};

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string, // Optional description
  imageUrl: PropTypes.string.isRequired,
  category: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string), // Array of strings
    PropTypes.string, // Single string
  ]).isRequired,
  index: PropTypes.number.isRequired,
  linkUrl: PropTypes.string, // Optional, defaults to '#'
};

export default ProjectCard;
