import React from 'react';
import Projects from '../components/Projects';


function ProjectsPage() {
  return (
    <section className="projects-page">
      <Projects />
    </section>
  );
}

export default ProjectsPage;
