const skillsData = {
  codingSkills: [
    {
      id: 1,
      skill: 'Front-End Development',
      level: 90,
      icon: 'fas fa-laptop-code', // Font Awesome icon class
    },
    {
      id: 2,
      skill: 'HTML & CSS',
      level: 90,
      icon: 'fab fa-html5', // Font Awesome HTML5 icon
    },
    {
      id: 3,
      skill: 'JavaScript & Frameworks',
      level: 75,
      icon: 'fab fa-js', // Font Awesome JS icon
    },
    {
      id: 4,
      skill: 'Web Development Frameworks',
      level: 85,
      icon: 'fas fa-cogs', // Font Awesome cogs icon
    },
    {
      id: 5,
      skill: 'E-commerce Solutions',
      level: 75,
      icon: 'fas fa-shopping-cart', // Font Awesome shopping cart icon
    }
  ],
  designSkills: [
    {
      id: 6,
      skill: 'Design & User Experience',
      level: 80,
      icon: 'fas fa-paint-brush', // Font Awesome paint brush icon
    },
    {
      id: 7,
      skill: 'Design Tools',
      level: 70,
      icon: 'fas fa-tools', // Font Awesome tools icon
    },
    {
      id: 8,
      skill: 'Graphic Design Software',
      level: 80,
      icon: 'fas fa-desktop', // Font Awesome desktop icon
    },
  ],
};

export default skillsData;
